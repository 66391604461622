import React, {useEffect, useState} from 'react';
import TacGeneration from '../../components/TacGeneration/TacGeneration';
import {fetchRegistrationCsrfToken} from '../../handlers/Common/CsrfHandlers';
import {fetchUserDetails} from '../../handlers/Common/UserDetailsHandler';
import InternalServerError from '../../components/Alerts/InternalServerError';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import {isLocalEnv} from '../../utils/envHelper';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {handleRegLogout} from '../../handlers/Common/LogoutHandlers';
import {REG_PAGE_TIMEOUT_IN_MS} from '../../utils/constants';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

const TacRegistration = () => {
  const [csrfToken, setCsrfToken] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [serverError, setServerError] = useState<boolean>(false);
  const bundle = useAuthgardBundle();

  useEffect(() => {
    document.title = bundle.getMessage('generate_tac_document_title');
    if (!isLocalEnv) {
      handleCsrf();
      handleUserDetails();
    }

    // Start page timeout
    const pageTimeout = setTimeout(() => {
      handleRegLogout(true);
    }, REG_PAGE_TIMEOUT_IN_MS);

    // Clear timeout on component unmount
    return () => {
      clearTimeout(pageTimeout);
    };
  }, []);

  const handleCsrf = async () => {
    const token = await fetchRegistrationCsrfToken();
    if (token != '') {
      setCsrfToken(token);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.CSRF_SERVER_ERROR);
      setServerError(true);
    }
  };

  const handleUserDetails = async () => {
    const userDetails = await fetchUserDetails();
    if (userDetails == null) {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.USER_DETAILS_SERVER_ERROR);
      setServerError(true);
    } else {
      setUserName(userDetails.userName);
    }
  };

  return <ResponsiveContentLayout>
    {serverError ? <InternalServerError/> : <TacGeneration userName={userName} csrfToken={csrfToken}/>}
  </ResponsiveContentLayout>;
};

export default TacRegistration;
