import {encryptPin} from '../Common/EncryptionPinHandlers';
import {REQUIRED_PIN_LENGTH} from '../../utils/constants';
import {handleClientSideMetrics} from '../Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

export const handlePinRegistration = async (pin: string, reenteredPin: string,
    csrfToken: string, publicKey: CryptoKey | undefined) => {
  if (!csrfToken && csrfToken == '') {
    return false;
  }
  if (publicKey == undefined) {
    return false;
  }
  if (!pin || !reenteredPin || pin !== reenteredPin || pin.length !== REQUIRED_PIN_LENGTH) {
    return false;
  }
  const pinJwe = await encryptPin(pin, publicKey);
  if (pinJwe == undefined) {
    return false;
  }
  const pinRegResponse = await persistUserPin(pinJwe, csrfToken);
  return pinRegResponse;
};

export const persistUserPin = async (pinJwe: string, csrfToken:string) => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;
  try {
    const response = await fetch(`${apiBaseUrl}/identity/registerPin`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'anti-csrftoken-a2z': csrfToken,
      },
      body: JSON.stringify({pin: pinJwe}),
    });
    if (response.ok) {
      return true;
    } else {
      // Show custom error message
      console.error('Error registering pin due to status code: ' + response.status);
      return false;
    }
  } catch (error) {
    handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
        ClientMetricsCodesEnum.PIN_REGISTRATION_SERVER_ERROR);
    console.error('Error registering pin: ', error);
    return false;
  }
};
