import React, {useEffect, useState} from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import Button from '@amzn/awsui-components-react/polaris/button';
import {isFirefox} from 'react-device-detect';
import {
  AuthgardCredentialResponse,
  createCredentials,
  fetchRegistrationChallenge,
  persistCredentials,
} from '../../handlers/FIDO2/FIDO2RegistrationHandlers';
import RegistrationSuccessAutoLogout from '../../components/Alerts/RegistrationSuccessAutoLogout';
import RegistrationFailure from '../../components/Alerts/RegistrationFailure';
import {handleRegLogout} from '../../handlers/Common/LogoutHandlers';
import {fetchRegistrationCsrfToken} from '../../handlers/Common/CsrfHandlers';
import TimerOverlay from '../../components/TimerOverlay/TimerOverlay';
import RegistrationFailureAutoLogout from '../../components/Alerts/RegistrationFailureAutoLogout';
import {isLocalEnv} from '../../utils/envHelper';
import {REG_PAGE_TIMEOUT_IN_MS, WEB_AUTHN_TIMEOUT_IN_S} from '../../utils/constants';
import CenteredContainer from '../../components/Layout/CenteredContainer';
import {Icon} from '@amzn/awsui-components-react';
import {fetchUserDetails} from '../../handlers/Common/UserDetailsHandler';
import InternalServerError from '../../components/Alerts/InternalServerError';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

/* eslint-disable @typescript-eslint/no-var-requires */
const securityKey = require('../../assets/images/securityKey.png');

const FIDO2Registration = () => {
  const [userDisplayName, setUserDisplayName] = useState<string>('');
  const [registrationSuccess, setRegistrationSuccess] = useState<boolean>();
  const [showWebauthnTimer, setShowWebauthnTimer] = useState<boolean>(false);
  const [retryLimit, setRetryLimit] = useState<number>(3);
  const [autoLogout, setAutoLogout] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);
  const [csrfToken, setCsrfToken] = useState<string>('');

  const bundle = useAuthgardBundle();

  useEffect(() => {
    document.title = bundle.getMessage('registration_document_title');

    if (!isLocalEnv) {
      handleUserDetails();
      handleCsrf();
    }

    // Start page timeout
    const pageTimeout = setTimeout(() => {
      handleRegLogout(true);
    }, REG_PAGE_TIMEOUT_IN_MS);

    // Clear timeout on component unmount
    return () => {
      clearTimeout(pageTimeout);
    };
  }, []);

  useEffect(() => {
    if (retryLimit == 0) setAutoLogout(true);
  }, [retryLimit]);

  const handleUserDetails = async () => {
    const userDetails = await fetchUserDetails();
    if (userDetails == null) {
      setAutoLogout(true);
    } else {
      setUserDisplayName(userDetails.userName);
    }
  };

  const handleCsrf = async () => {
    const token = await fetchRegistrationCsrfToken();
    if (token != '') {
      setCsrfToken(token);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.CSRF_SERVER_ERROR);
      setServerError(true);
    }
  };

  const handleRegistration = async () => {
    let creds:AuthgardCredentialResponse | undefined;
    setLoading(true);
    setRegistrationSuccess(undefined);
    try {
      const challengeResponse = await fetchRegistrationChallenge();
      if (challengeResponse == null) {
        handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
            ClientMetricsCodesEnum.FIDO2_REGISTRATION_INVALID_CHALLENGE);
        throw new Error('Could not begin registration: challenge missing.');
      }

      // Start webauthn prompt
      if (isFirefox) {
        setShowWebauthnTimer(false);
        creds = await createCredentials(challengeResponse);
      } else {
        setShowWebauthnTimer(true);
        creds = await createCredentials(challengeResponse);
        setShowWebauthnTimer(false);
      }

      if (creds === undefined) {
        handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
            ClientMetricsCodesEnum.FIDO2_REGISTRATION_INVALID_CRED);
        throw new Error('Could not complete registration: undefined credentials');
      }

      const success = await persistCredentials(creds, csrfToken);
      setRegistrationSuccess(success);
      setLoading(false);
    } catch (error) {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.FIDO2_REGISTRATION_SERVER_ERROR);
      setRetryLimit(retryLimit - 1);
      setRegistrationSuccess(false);
      setShowWebauthnTimer(false);
      setLoading(false);
    }
  };

  let registrationAlert;
  if (autoLogout) {
    registrationAlert = <RegistrationFailureAutoLogout/>;
  } else if (registrationSuccess !== undefined) {
    registrationAlert = registrationSuccess ? <RegistrationSuccessAutoLogout/> : <RegistrationFailure/>;
  } else if (serverError) {
    registrationAlert = <InternalServerError/>;
  }

  const showRegistrationForm = !autoLogout && !registrationSuccess && !serverError;

  const header: JSX.Element =
    <Header
      variant="h1"
      description={bundle.getMessage('registration_title_description')}
    >
      {bundle.getMessage('registration_title',
          {userName: userDisplayName},
      )}
    </Header>;

  const footer: JSX.Element = <div style={{display: 'flex', justifyContent: 'end'}}>
    <SpaceBetween direction="horizontal" size="s" >
      <Button
        onClick={handleRegistration}
        variant="primary"
        loading={loading}
      >
        {bundle.getMessage('app_continue')}
      </Button>
    </SpaceBetween>
  </div>;

  return (
    <ResponsiveContentLayout>
      <SpaceBetween size="l">
        {registrationAlert}
        {showRegistrationForm &&
          <CenteredContainer header={header} footer={footer}>
            <SpaceBetween direction='vertical' alignItems='center' size='xs'>
              <img src={securityKey} width={278} height={164} alt="security key"/>
              <p>
                <SpaceBetween direction="vertical" alignItems="center" size="xxs">
                  {bundle.getMessage('registration_description')}
                  <SpaceBetween direction="horizontal" size="xxs">
                    <Icon name="status-info" />
                    {bundle.getMessage('registration_timer_description')}
                  </SpaceBetween>
                </SpaceBetween>
              </p>
            </SpaceBetween>
          </CenteredContainer>
        }
        {showWebauthnTimer &&
          <TimerOverlay
            timeout={WEB_AUTHN_TIMEOUT_IN_S}
            onExpire={() => setShowWebauthnTimer(false)}
          />
        }
      </SpaceBetween>
    </ResponsiveContentLayout>
  );
};

export default FIDO2Registration;
