import React, {useState, useEffect} from 'react';
import {fetchRegistrationCsrfToken} from '../../handlers/Common/CsrfHandlers';
import TacGeneration from '../../components/TacGeneration/TacGeneration';
import PinInput from '../../components/PinInput/PinInput';
import {handlePinRegistration} from '../../handlers/Pin/PinRegistrationHandlers';
import {fetchIdentityPublicKey} from '../../handlers/Common/EncryptionPinHandlers';
import InternalServerError from '../../components/Alerts/InternalServerError';
import {isLocalEnv} from '../../utils/envHelper';
import {fetchUserDetails} from '../../handlers/Common/UserDetailsHandler';
import ResponsiveContentLayout from '../../components/Layout/ResponsiveContentLayout';
import {useAuthgardBundle} from '../../localizations/arb/AuthgardArbBundle';
import {handleRegLogout} from '../../handlers/Common/LogoutHandlers';
import {REG_PAGE_TIMEOUT_IN_MS} from '../../utils/constants';
import {handleClientSideMetrics} from '../../handlers/Common/ClientMetricsHandler';
import {ClientMetricsAttributesEnum, ClientMetricsCodesEnum} from '../../utils/ClientMetricsCodes';

const TacAndPinRegistration = () => {
  const [registeredPin, setRegisteredPin] = useState<boolean>(false);
  const [csrfToken, setCsrfToken] = useState<string>('');
  const [publicKey, setPublicKey] = useState<CryptoKey>();
  const [userName, setUserName] = useState<string>('');
  const [serverError, setServerError] = useState<boolean>(false);
  const bundle = useAuthgardBundle();

  useEffect(() => {
    document.title = bundle.getMessage('registration_document_title');

    if (!isLocalEnv) {
      handlePublicKey();
      handleCsrf();
      handleUserDetails();
    }

    // Start page timeout
    const pageTimeout = setTimeout(() => {
      handleRegLogout(true);
    }, REG_PAGE_TIMEOUT_IN_MS);

    // Clear timeout on component unmount
    return () => {
      clearTimeout(pageTimeout);
    };
  }, []);

  const handlePublicKey = async () => {
    const key = await fetchIdentityPublicKey();
    if (key != undefined) {
      setPublicKey(key);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.PIN_JWKS_SERVER_ERROR);
      setServerError(true);
    }
  };

  const handleCsrf = async () => {
    const token = await fetchRegistrationCsrfToken();
    if (token != '') {
      setCsrfToken(token);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.CSRF_SERVER_ERROR);
      setServerError(true);
    }
  };

  const handleUserDetails = async () => {
    const userDetails = await fetchUserDetails();
    if (userDetails != null) {
      setUserName(userDetails.userName);
    } else {
      handleClientSideMetrics(ClientMetricsAttributesEnum.CLIENT_ERROR,
          ClientMetricsCodesEnum.USER_DETAILS_SERVER_ERROR);
      setServerError(true);
    }
  };

  const handleRegisteredPin = async (pin: string, reenterPin: string): Promise<any> => {
    const success = await handlePinRegistration(pin, reenterPin, csrfToken, publicKey);
    if (success) {
      setRegisteredPin(true);
    } else {
      setServerError(true);
    }
  };

  return (
    <ResponsiveContentLayout>
      {serverError ?
        <InternalServerError/> :
        <div>
          {registeredPin ? <TacGeneration userName={userName} csrfToken={csrfToken}/> :
           <PinInput onSubmit={handleRegisteredPin} userName={userName}/>}
        </div>
      }
    </ResponsiveContentLayout>
  );
};

export default TacAndPinRegistration;
