export const handleClientSideMetrics = (clientAttribute: string, clientValue: string) => {
  const apiBaseUrl = `https://api.${window.location.hostname}`;

  // Fire and forget API call - no await
  fetch(`${apiBaseUrl}/clientMetrics`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      clientMetricsAttribute: clientAttribute,
      clientMetricsValue: clientValue,
    }),
  }).catch((err) => console.error('Failed to log client metrics:', err));
};
